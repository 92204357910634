import { Avatar, Button, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import data from "../../utilities/constants";
import {
  banUserIcon,
  checkCircleWhiteIcon,
  deleteConvIcon,
  deleteIcon,
  locationArrowIcon,
  MoreVertIcon,
  plusCircleArticleIcon,
  unresolvedIcon,
  publishIcon,
  assignIcon,
} from "../../assets";
import {
  blockConversation,
  updateConversationStatus,
} from "../../slices/conversation";
import { openModal } from "../../slices/modals";
import { emitEvent } from "../../socket/socket";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";
import CustomButton from "../CustomButton";
import { isFound } from "../../utilities/isFound";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
const ContentHeader = () => {
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const [newArticle, setNewArticle] = useState(false);
  const { user } = useSelector((state) => state.user);
  const {
    clientConversationStored,
    statusClientConversation,
    userStatus,
    query,
  } = useSelector((state) => state.conversations);

  const { messagesStored } = useSelector((state) => state.messages);
  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const dispatch = useDispatch();
  const { todolist, status } = useSelector((state) => state.todolist);
  const { statusUpdatingConversationStatus } = useSelector(
    (state) => state.conversations
  );
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTriggerToggle = () => {
    if (messagesStored) {
      dispatch(
        updateConversationStatus({
          conversationId: messagesStored[0]?.conversation,
          websiteId: user?.websiteID,
          query: query?.query,
        })
      ).then((res) => {
        if (!res.error) {
          emitEvent("RESOLVED_FROM_OPERATOR", {
            conversationId: messagesStored[0]?.conversation,
            websiteId: user?.websiteID,
            status: res?.payload?.status,
          });
        }
      });
    }
  };
  const { t } = useTranslation();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "white" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };
  const { isArticleEmpty } = useSelector((state) => state.helpdesk);
  const { state: article } = useLocation();
  return (
    <div
      className={
        pathname?.split("/")[2] === "open"
          ? "content-header content-header-width"
          : pathname.includes("/messaging") &&
            statusClientConversation !== "succeeded"
          ? ""
          : "content-header"
      }
    >
      {!pathname.includes("/messaging") ? (
        <div
          className="header-title"
          style={{
            padding:
              pathname?.split("/")[1] === "help-desk" &&
              (pathname?.split("/")[2] === "articles" ||
                pathname?.split("/")[2] === "visible" ||
                pathname?.split("/")[2] === "draft") &&
              "0px",
          }}
        >
          {pathname?.split("/")[2] === "open" ||
          pathname?.split("/")[2] === "assigned" ||
          pathname?.split("/")[2] === "complete" ? (
            status === "loading" ? (
              <CircularProgress size="16px" />
            ) : (
              `${t("Issues")} (${
                todolist[
                  pathname?.split("/")[2] === "assigned"
                    ? "assigned"
                    : pathname?.split("/")[2] === "open"
                    ? "opened"
                    : "completed"
                ]?.length
              })`
            )
          ) : (pathname?.split("/")[2] === "articles" ||
              pathname?.split("/")[2] === "draft" ||
              pathname?.split("/")[2] === "visible") &&
            !pathname?.split("/")[3] ? (
            <React.Fragment>
              <>
                <span className="article-text">{t("Articles")}</span>
                {user?.role?.code !== "OPERATOR" && (
                  <Link
                    to={
                      pathname?.split("/")[2] === "articles"
                        ? "/help-desk/articles/create"
                        : pathname?.split("/")[2] === "visible"
                        ? "/help-desk/visible/create"
                        : pathname?.split("/")[2] === "draft"
                        ? "/help-desk/draft/create"
                        : ""
                    }
                  >
                    <CustomButton
                      image={plusCircleArticleIcon}
                      alt="plus-circle-article"
                      text={t("New article or Category")}
                      className="articles-btn"
                    />
                  </Link>
                )}
              </>
            </React.Fragment>
          ) : (pathname?.split("/")[2] === "articles" ||
              pathname?.split("/")[2] === "visible" ||
              pathname?.split("/")[2] === "draft") &&
            pathname?.split("/")[3] === "create" ? (
            <>
              <span className="article-text">{t("New Articles")}</span>
              <div className="new-article-btns">
                <Link to="/help-desk/articles">
                  <CustomButton
                    link={
                      pathname?.split("/")[2] === "articles"
                        ? "/help-desk/articles"
                        : pathname?.split("/")[2] === "visible"
                        ? "/help-desk/visible"
                        : pathname?.split("/")[2] === "draft"
                        ? "/help-desk/draft"
                        : ""
                    }
                    text={t("Cancel")}
                    className="articles-btn"
                    onClick={() => {
                      setNewArticle(false);
                    }}
                    style={{
                      color: "#000",
                      padding: "10px 10px 10px 9px",
                      minWidth: " 136px",
                      height: "37px",
                    }}
                  />
                </Link>

                <CustomButton
                  image={publishIcon}
                  alt="plus-circle-article"
                  text={t("Create Category")}
                  className="articles-btn "
                  onClick={() => dispatch(openModal("create-category"))}
                  style={{
                    background: "#fec423",
                    color: "#fff",
                    padding: "10px 10px 10px 9px",
                    minWidth: " 136px",
                    height: "37px",
                  }}
                />
                <CustomButton
                  image={publishIcon}
                  alt="plus-circle-article"
                  text={t("Publish New Article")}
                  className="articles-btn "
                  onClick={() =>
                    isArticleEmpty
                      ? dispatch(openModal("add-article"))
                      : showSnackbar({
                          variant: "error",
                          message: t("You Have To Write An Article First"),
                        })
                  }
                  style={{
                    background: "#fec423",
                    color: "#fff",
                    padding: "10px 10px 10px 9px",
                    minWidth: " 136px",
                    height: "37px",
                  }}
                />
              </div>
            </>
          ) : (pathname?.split("/")[2] === "articles" ||
              pathname?.split("/")[2] === "visible" ||
              pathname?.split("/")[2] === "draft") &&
            pathname?.split("/")[3] === "edit" ? (
            <>
              <span className="article-text">{t("Edit Article")}</span>
              <div className="new-article-btns">
                <Link
                  to={
                    pathname?.split("/")[2] === "articles"
                      ? "/help-desk/articles"
                      : pathname?.split("/")[2] === "visible"
                      ? "/help-desk/visible"
                      : pathname?.split("/")[2] === "draft"
                      ? "/help-desk/draft"
                      : ""
                  }
                >
                  <CustomButton
                    link={
                      pathname?.split("/")[2] === "articles"
                        ? "/help-desk/articles"
                        : pathname?.split("/")[2] === "visible"
                        ? "/help-desk/visible"
                        : pathname?.split("/")[2] === "draft"
                        ? "/help-desk/draft"
                        : ""
                    }
                    text={t("Cancel")}
                    className="articles-btn"
                    onClick={() => {
                      setNewArticle(false);
                    }}
                    style={{
                      color: "#000",
                      padding: "10px 10px 10px 9px",
                      minWidth: " 136px",
                      height: "37px",
                    }}
                  />
                </Link>

                <CustomButton
                  image={publishIcon}
                  alt="plus-circle-article"
                  text={t("Edit Article")}
                  className="articles-btn "
                  onClick={() =>
                    dispatch(
                      openModal("add-article", {
                        article: article,
                      })
                    )
                  }
                  style={{
                    background: "#fec423",
                    color: "#fff",
                    padding: "10px 10px 10px 9px",
                    minWidth: " 136px",
                    height: "37px",
                  }}
                />
              </div>
            </>
          ) : (pathname?.split("/")[2] === "articles" ||
              pathname?.split("/")[2] === "visible" ||
              pathname?.split("/")[2] === "draft") &&
            pathname?.split("/")[3] === "show" ? (
            <>
              <span className="article-text">{t("Show Article")}</span>
              <div className="new-article-btns">
                <Link
                  to={
                    pathname?.split("/")[2] === "articles"
                      ? "/help-desk/articles"
                      : pathname?.split("/")[2] === "visible"
                      ? "/help-desk/visible"
                      : pathname?.split("/")[2] === "draft"
                      ? "/help-desk/draft"
                      : ""
                  }
                >
                  <CustomButton
                    link={
                      pathname?.split("/")[2] === "articles"
                        ? "/help-desk/articles"
                        : pathname?.split("/")[2] === "visible"
                        ? "/help-desk/visible"
                        : pathname?.split("/")[2] === "draft"
                        ? "/help-desk/draft"
                        : ""
                    }
                    text={t("Cancel")}
                    className="articles-btn"
                    onClick={() => {
                      setNewArticle(false);
                    }}
                    style={{
                      color: "#000",
                      padding: "10px 10px 10px 9px",
                      minWidth: " 136px",
                      height: "37px",
                    }}
                  />
                </Link>
              </div>
            </>
          ) : (
            t(
              pathname?.split("/")[2]?.charAt(0)?.toUpperCase() +
                pathname?.split("/")[2]?.slice(1)
            )
          )}
        </div>
      ) : (
        statusClientConversation === "succeeded" && (
          <div
            className="header-messaging-container"
            style={{
              padding:
                Object.keys(clientConversationStored).length === 0
                  ? "20.8px"
                  : (user?.role?.code !== "OPERATOR" ||
                      clientConversationStored?.assigned?.user?._id !==
                        user?._id) &&
                    (!["ADMIN", "SUPER-ADMIN"].includes(user?.role?.code) ||
                      !clientConversationStored?.assigned?.user?._id) &&
                    "2px",
            }}
          >
            <>
              <div>
                <Avatar
                  className="content-header-avatar"
                  src={clientConversationStored?.meta?.avatar}
                />
                <div>
                  <React.Fragment>
                    <Typography className="client-name">
                      {clientConversationStored.meta?.name
                        ? clientConversationStored.meta?.name
                        : "Guest"}
                      {clientConversationStored?.userFeedback?.rating && (
                        <LazyLoadImage
                          width="18px"
                          height="18px"
                          style={{ marginLeft: "5px" }}
                          src={
                            data?.FEEDBACKS.find(
                              (feedback) =>
                                feedback.id ===
                                clientConversationStored?.userFeedback?.rating
                            )?.expression
                          }
                          alt="feedback"
                        />
                      )}
                    </Typography>
                    <Typography className="profession">
                      {isFound(userStatus, clientConversationStored?._id)
                        ? t(
                            userStatus?.find(
                              (el) => el?._id === clientConversationStored?._id
                            )?.status
                          )
                        : t("Not available yet")}
                    </Typography>
                  </React.Fragment>
                </div>
              </div>
              <div>
                {((user.role?.code === "OPERATOR" &&
                  user?._id ===
                    clientConversationStored?.assigned?.user?._id) ||
                  (["ADMIN", "SUPER-ADMIN"].includes(user.role?.code) &&
                    clientConversationStored?.assigned?.user?._id)) && (
                  <React.Fragment>
                    {statusUpdatingConversationStatus === "loading" ? (
                      <LoadingButton
                        loading
                        loadingIndicator="Loading…"
                        variant="outlined"
                        style={{ padding: "7.3px 15px" }}
                      >
                        Fetch data
                      </LoadingButton>
                    ) : (
                      <button
                        className={
                          clientConversationStored?.status === "active"
                            ? "header-unresloved-btn"
                            : "header-resloved-btn"
                        }
                        onClick={handleTriggerToggle}
                      >
                        <LazyLoadImage
                          src={
                            clientConversationStored?.status === "active"
                              ? unresolvedIcon
                              : checkCircleWhiteIcon
                          }
                          alt="check-circle-icon"
                        />
                        <span>
                          {clientConversationStored?.status === "active"
                            ? t("Unresolved")
                            : t("Resolved")}
                        </span>
                      </button>
                    )}
                    <LazyLoadImage
                      src={MoreVertIcon}
                      alt="MoreVertIcon"
                      className="more-vert-icon"
                      onClick={handleClick}
                    />
                  </React.Fragment>
                )}
                <Menu
                  id="positioned-menu"
                  className="operator-functionalities-container"
                  aria-labelledby="positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  disableEnforceFocus
                >
                  {/* <MenuItem className="operator-functionality-container">
                  <img src={muteIcon} alt="mute-icon" />
                  <span className="operator-functionality-description">
                    Mute
                  </span>
                </MenuItem>
                <MenuItem className="operator-functionality-container">
                  <img src={clipboardIcon} alt="clipboardIcon" />
                  <span className="operator-functionality-description">
                    Issues Created
                  </span>
                </MenuItem> */}
                  <MenuItem
                    className="operator-functionality-container"
                    onClick={() => {
                      dispatch(
                        openModal("info-modal", {
                          conversationId: clientConversationStored?._id,
                          oldOperator: clientConversationStored?.assigned?.user,
                          operatorAssigned:
                            clientConversationStored?.assigned?.user?._id,
                          websiteId: user?.websiteID,
                          id: "assign-operator-modal",
                          icon: assignIcon,
                          title: t("Assign this client to an new operator"),
                          description: t(
                            "if you change the operator you will not be able to you reply, react, create issues and set reminders. all this fuctions will be open to your new operator!"
                          ),
                          btn_text: t("Change"),
                        })
                      );
                      handleClose();
                    }}
                  >
                    <LazyLoadImage
                      src={locationArrowIcon}
                      alt="locationArrowIcon"
                    />
                    <span className="operator-functionality-description">
                      {t("Assign To")}
                    </span>
                  </MenuItem>

                  {["ADMIN", "SUPER-ADMIN"].includes(user?.role?.code) && (
                    <MenuItem
                      className="operator-functionality-container"
                      onClick={() => {
                        dispatch(
                          openModal("delete-modal", {
                            id: "delete-conversation",
                            icon: deleteConvIcon,
                            conversationId: clientConversationStored?._id,
                            conversationStatus:
                              clientConversationStored?.status,
                            websiteID: user?.websiteID,
                            title: t(
                              "are you sure to delete this conversation?"
                            ),
                            description: (
                              <React.Fragment>
                                {t(
                                  "by clicking on delete this conversation will"
                                )}
                                <span>&nbsp;{t("Permanently")}&nbsp;</span>
                                {t(
                                  "deleted and you will not be able to recover it!"
                                )}
                              </React.Fragment>
                            ),
                          })
                        );
                        handleClose();
                      }}
                    >
                      <LazyLoadImage src={deleteIcon} alt="deleteIcon" />
                      <span className="operator-functionality-description">
                        {t("Delete")}
                      </span>
                    </MenuItem>
                  )}
                  <MenuItem
                    className="operator-functionality-container"
                    onMouseEnter={handleMouseOver}
                    onMouseLeave={handleMouseOut}
                    onClick={() =>
                      dispatch(
                        blockConversation({
                          websiteId: user?.websiteID,
                          conversationId: clientConversationStored?._id,
                          status: clientConversationStored?.isBanned
                            ? false
                            : true,
                        })
                      ).then((res) => {
                        !res?.error
                          ? showSnackbar({
                              variant: "success",
                              message: res?.payload?.isBanned
                                ? `This conversation has successfully Banned. you will never be able to receive message from ${
                                    clientConversationStored?.meta?.name
                                      ? `"${clientConversationStored?.meta?.name}"`
                                      : "this user"
                                  }`
                                : `This conversation has successfully Unbanned. ${
                                    clientConversationStored?.meta?.name
                                      ? `"${clientConversationStored?.meta?.name}"`
                                      : "The user "
                                  } can continue sending message to you`,
                            })
                          : showSnackbar({
                              variant: "error",
                              message: res?.error?.message,
                            });
                      })
                    }
                  >
                    <LazyLoadImage
                      src={banUserIcon}
                      alt="ban-user-icon"
                      className={isHovering ? "ban-user-icon" : ""}
                    />
                    <span
                      className={
                        isHovering
                          ? `operator-functionality-description ban-user-text`
                          : `operator-functionality-description`
                      }
                    >
                      {clientConversationStored?.isBanned
                        ? t("Unban User").replace(" ", "\xa0")
                        : t("Ban User")}
                    </span>
                  </MenuItem>
                </Menu>
              </div>
            </>
          </div>
        )
      )}
    </div>
  );
};
export default ContentHeader;
